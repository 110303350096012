<template>
  <div class="ctx-job-create px-8 quotation-create-page">
    <v-form
      ref="createTicketForm"
      v-model="formValid"
      lazy-validation
      v-on:submit.stop.prevent="updateOrCreate()"
    >
      <div class="py-3">
        <div class="form-action">
          <div class="form-title d-flex">
            Visit For
            <div
              v-if="vCustomerId"
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              {{ vCustomer.display_name }}
            </div>
            <template v-else>
              <div
                class="ml-3 form-title-link cursor-pointer text-h5"
                v-on:click="openDialog('customer')"
              >
                Select a Customer
                <v-icon large color="cyan">mdi-account-check-outline</v-icon>
              </div>
              <div class="mx-3">OR</div>
              <div
                class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
                v-on:click="createCustomerDialog()"
              >
                Create a new Customer
                <v-icon large color="cyan darken-4"
                  >mdi-plus-circle-outline</v-icon
                >
              </div>
            </template>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-chip
              v-if="jobOption?.priventive?.barcode"
              label
              color="chip-custom-blue"
              outlined
              class="text-white p-3 mr-4"
              style="font-size: 21px !important; font-weight: 700"
            >
              <template>
                {{ jobOption.priventive.barcode }}
              </template>
            </v-chip>
            <v-btn
              :disabled="jobLoading"
              :loading="jobLoading"
              v-on:click="goBack()"
              class="custom-bold-button custom-grey-border"
              depressed
              >Cancel</v-btn
            >
            <v-btn
              class="custom-bold-button ml-4"
              depressed
              :disabled="jobLoading"
              key=""
              :loading="jobLoading"
              v-on:click="updateOrCreate()"
              color="cyan white--text"
            >
              <template v-if="visitId > 0"> Update</template>
              <template v-else> Save</template>
            </v-btn>
          </div>
        </div>
      </div>
      <v-row>
        <v-col md="7">
          <label class="ml-2 required" :for="`ctx-job-title-${uniqueId}`"
            >Title</label
          >
          <TextField
            id="job-title"
            dense
            filled
            placeholder="Title"
            solo
            flat
            counter="50"
            v-model="jobCreate.title"
            color="cyan"
            :maxlength="250"
            :rules="[validateRules.required(jobCreate.title, 'Title')]"
          />

          <TextAreaField
            auto-grow
            dense
            filled
            color="cyan"
            v-model="jobCreate.description"
            placeholder="Description"
            solo
            flat
            row-height="25"
            counter="250"
          />

          <!-- <v-text-field
            :id="`ctx-job-title-${uniqueId}`"
            dense
            filled
            placeholder="Title"
            solo
            flat
            :disabled="jobLoading"
            v-model="jobCreate.title"
            color="cyan"
            class="mb-2"
            :rules="[validateRules.required(jobCreate.title, 'Title')]"
          />
          <v-textarea
            :id="`ctx-job-description-${uniqueId}`"
            auto-grow
            dense
            filled
            color="cyan"
            placeholder="Description"
            solo
            flat
            :disabled="jobLoading"
            v-model="jobCreate.description"
            row-height="25"
            class="mb-2"
          /> -->
          <v-row>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3
                  ><label class="my-0">Billing Location</label></v-flex
                >
                <v-flex md9 class="position-relative">
                  <v-icon
                    v-on:click="openDialog('billing')"
                    small
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBilling?.id">
                <span v-if="vBilling && vBilling.property_address">
                  <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                >
                <p v-if="vBilling && vBilling.property_address">
                  {{ vBilling.street_1 }},
                  <br v-if="vBilling.street_2 || vBilling.unit_no" />
                  <template v-if="vBilling.street_2">
                    {{ vBilling.street_2 }},
                  </template>
                  <template v-if="vBilling.unit_no">
                    {{ vBilling.unit_no }},
                  </template>
                  <br />
                  {{ vBilling.country }}
                  <template v-if="vBilling && vBilling.zip_code != '000000'">
                    , {{ vBilling.zip_code }}
                  </template>
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select billing location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('billing-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBillingContact?.id">
                <p class="mb-0">{{ vBillingContact.display_name }}</p>
                <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                <p class="mb-0">{{ vBillingContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="jobCreate.billing_contact"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3><label class="my-0">Site Location</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vProperty?.id">
                <span v-if="vProperty && vProperty.property_address">
                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                >
                <p class="mr-2" v-if="vProperty && vProperty.property_address">
                  {{ vProperty.street_1 }},
                  <br v-if="vProperty.street_2 || vProperty.unit_no" />
                  <template v-if="vProperty.street_2">
                    {{ vProperty.street_2 }},
                  </template>
                  <template v-if="vProperty.unit_no">
                    {{ vProperty.unit_no }},
                  </template>
                  <br />
                  {{ vProperty.country }}
                  <template v-if="vProperty && vProperty.zip_code != '000000'">
                    , {{ vProperty.zip_code }}
                  </template>
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select site location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vPropertyContact?.id">
                <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="jobCreate.property_contact"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
          <div v-if="false">
            <label>Contract Number </label>
            <v-autocomplete
              id="contract"
              dense
              v-model="jobCreate.contract"
              :items="vOption.contract_list"
              item-text="barcode"
              v-on:click:clear="contractDetail = {}"
              clearable
              item-color="cyan"
              v-on:change="getContractDetail($event)"
              item-value="id"
              filled
              hide-details
              placeholder="Contract"
              solo
              flat
              color="cyan"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title class="px-0">{{
                    data.item.job_title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.barcode
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    Start Date : {{ formatDate(data.item.contract_start) }}
                  </div>
                  <div>End Date : {{ formatDate(data.item.contract_end) }}</div>
                </v-list-item-action>
              </template>
            </v-autocomplete>
            <v-list
              v-if="
                jobCreate.contract &&
                contractDetail &&
                contractDetail.is_contract
              "
            >
              <v-list-item class="grey lighten-4 ml-1">
                <v-list-item-content>
                  <v-list-item-title class="px-0">
                    <h5>
                      {{ contractDetail.job_title }}
                    </h5>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    contractDetail.barcode
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex">
                    <div class="mx-5">
                      <v-chip class="mr-2" color="blue" outlined>
                        <span class="font-size-15">{{
                          contractDetail.total_service
                        }}</span>
                      </v-chip>
                      <b>Total Visit </b>
                    </div>
                    <div class="mx-5">
                      <v-chip class="mr-2" color="red" outlined>
                        <span class="font-size-15">{{
                          contractDetail.visit_count
                        }}</span>
                      </v-chip>
                      <b>Consumed </b>
                    </div>
                    <div class="mx-5">
                      <v-chip class="mr-2" color="orange" outlined>
                        <span class="font-size-15">{{
                          getBalance(contractDetail)
                        }}</span>
                      </v-chip>
                      <b>Balance</b>
                    </div>
                  </div>
                </v-list-item-action>
                <v-list-item-action>
                  <div>
                    <b>Start Date </b> :
                    {{ formatDate(contractDetail.contract_start) }}
                  </div>
                  <div>
                    <b>End Date </b>:
                    {{ formatDate(contractDetail.contract_end) }}
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col md="5">
          <table width="100%" v-if="false">
            <tr>
              <td width="200px">
                <label :for="`ctx-job-visit-number-${uniqueId}`"
                  >Visit Number</label
                >
              </td>
              <td>
                <v-text-field
                  :id="`ctx-job-visit-number-${uniqueId}`"
                  dense
                  filled
                  class="font-weight-700"
                  :disabled="jobLoading"
                  readonly
                  :value="
                    jobEditData?.visit?.barcode ?? jobOption.visit_barcode
                  "
                  placeholder="Visit Number"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td width="200px">
                <label :for="`ctx-job-priority-${uniqueId}`">Priority</label>
              </td>
              <td>
                <div class="d-flex ml-1">
                  <v-btn
                    depressed
                    tile
                    :disabled="jobLoading"
                    :outlined="!(jobCreate.priority == 1)"
                    v-on:click="jobCreate.priority = 1"
                    value="1"
                    color="red darken-3 white--text"
                    >High</v-btn
                  >
                  <v-btn
                    class="mx-2"
                    depressed
                    tile
                    :disabled="jobLoading"
                    :outlined="!(jobCreate.priority == 2)"
                    v-on:click="jobCreate.priority = 2"
                    value="2"
                    color="orange darken-4 white--text"
                    >Medium</v-btn
                  >
                  <v-btn
                    depressed
                    tile
                    :disabled="jobLoading"
                    :outlined="!(jobCreate.priority == 3)"
                    v-on:click="jobCreate.priority = 3"
                    value="3"
                    color="light-green darken-3 white--text"
                    >Low</v-btn
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td width="200px">
                <label :for="`ctx-job-amount-to-be-collected-${uniqueId}`"
                  >Amount to be collected</label
                >
              </td>
              <td>
                <v-text-field
                  :id="`ctx-job-amount-to-be-collected-${uniqueId}`"
                  dense
                  filled
                  :disabled="jobLoading"
                  placeholder="Amount to be collected"
                  solo
                  flat
                  type="number"
                  v-model="jobCreate.collected_amount"
                  v-on:keypress="
                    limitDecimal($event, jobCreate.collected_amount)
                  "
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <label
                  class="mb-0"
                  :for="`ctx-job-service-location-${uniqueId}`"
                >
                  Service Location
                  <v-icon
                    :disabled="jobLoading"
                    v-on:click="propertyDialog = true"
                    class="mx-4"
                    small
                    color="cyan"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small color="cyan">mdi-history</v-icon>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p v-if="vPropertyId" class="mb-0">
                  {{ vProperty?.property_address }}
                </p>
                <p v-else-if="vCustomerId" class="mb-0 red--text">
                  Please select service location
                </p>
                <p v-else class="mb-0 red--text">Please select customer</p>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <label class="mb-0" :for="`ctx-job-contact-details-${uniqueId}`"
                  >Contact Details</label
                >
              </td>
            </tr>
            <tr v-if="vPropertyContactId">
              <td colspan="2">
                <p class="mb-1">{{ vPropertyContact?.display_name }}</p>
                <p class="mb-1">{{ vPropertyContact?.primary_phone }}</p>
                <p class="mb-1">{{ vPropertyContact?.primary_email }}</p>
              </td>
            </tr>
            <tr v-else-if="vCustomerId">
              <td colspan="2">
                <p class="mb-0 red--text">Please select contact person</p>
              </td>
            </tr>
            <tr v-else>
              <td colspan="2">
                <p class="mb-0 red--text">Please select customer</p>
              </td>
            </tr>
            <tr>
              <td>
                <label :for="`ctx-job-email-notification-${uniqueId}`"
                  >Email Notification</label
                >
              </td>
              <td>
                <v-switch
                  v-model="jobCreate.email_notify"
                  :true-value="1"
                  :false-value="0"
                  :disabled="jobLoading"
                  class="m-0 p-0"
                  color="cyan"
                  dense
                  inset
                />
              </td>
            </tr>
          </table>
          <table width="100%" style="table-layout: fixed">
            <tr>
              <td width="20%">
                <v-chip
                  v-if="$route.query['quote-type'] == 1"
                  label
                  color="light-green darken-3"
                  text-color="white"
                  class="text-uppercase font-weight-600"
                  >New</v-chip
                >
                <v-chip
                  v-if="$route.query['quote-type'] == 2"
                  label
                  color="orange darken-4 white--text"
                  text-color="white"
                  class="text-uppercase font-weight-600"
                  >Maintenance</v-chip
                >
                <v-chip
                  v-if="$route.query['enquiry']"
                  label
                  color="yellow darken-2 black--text"
                  class="text-uppercase font-weight-600"
                  >Investigation</v-chip
                >
              </td>
              <td></td>
            </tr>

            <tr v-if="false">
              <td width="20%">
                <div class="d-flex ml-1 mt-4">
                  <v-btn
                    depressed
                    tile
                    :outlined="!(jobCreate.option == 'internal')"
                    v-on:click="jobCreate.option = 'internal'"
                    value="internal"
                    color="red darken-3 white--text"
                    style="font-size: 15px; margin-right: 20px"
                    >Internal</v-btn
                  >
                  <v-btn
                    style="font-size: 15px; margin-right: 20px"
                    class="mx-2"
                    depressed
                    tile
                    :outlined="!(jobCreate.option == 'external')"
                    v-on:click="jobCreate.option = 'external'"
                    value="external"
                    color="green darken-4 white--text"
                    >External</v-btn
                  >
                </div>
              </td>
              <td></td>
            </tr>

            <tr>
              <template>
                <td width="20%">
                  <label for="job-id">Visit #</label>
                </td>
                <td>
                  <v-text-field
                    id="job-id"
                    dense
                    filled
                    class="font-weight-700"
                    readonly
                    hide-details
                    placeholder="Visit # / Job #"
                    solo
                    flat
                    :value="
                      jobEditData?.visit?.barcode ?? jobOption.visit_barcode
                    "
                    color="cyan"
                  />
                </td>
              </template>
            </tr>
            <tr>
              <td width="20%">
                <label for="priority">Priority</label>
              </td>
              <td>
                <div class="d-flex ml-1">
                  <v-btn
                    depressed
                    tile
                    :disabled="jobLoading"
                    :outlined="!(jobCreate.priority == 1)"
                    v-on:click="jobCreate.priority = 1"
                    value="1"
                    color="red darken-3 white--text"
                    >High</v-btn
                  >
                  <v-btn
                    class="mx-2"
                    depressed
                    tile
                    :disabled="jobLoading"
                    :outlined="!(jobCreate.priority == 2)"
                    v-on:click="jobCreate.priority = 2"
                    value="2"
                    color="orange darken-4 white--text"
                    >Medium</v-btn
                  >
                  <v-btn
                    depressed
                    tile
                    :disabled="jobLoading"
                    :outlined="!(jobCreate.priority == 3)"
                    v-on:click="jobCreate.priority = 3"
                    value="3"
                    color="light-green darken-3 white--text"
                    >Low</v-btn
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label for="reference-no">Reference #</label>
              </td>
              <td>
                <v-text-field
                  id="reference-no"
                  dense
                  filled
                  v-model="jobCreate.reference"
                  hide-details
                  placeholder="Reference #"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="job-type" class="required">Job Type</label>
              </td>
              <td>
                <v-autocomplete
                  id="job-type"
                  dense
                  multiple
                  v-model="jobCreate.job_type"
                  :items="jobOption.ticket_types"
                  item-text="value"
                  item-color="cyan"
                  item-value="value"
                  filled
                  hide-details
                  placeholder="Job Type"
                  solo
                  flat
                  color="cyan"
                  :rules="[
                    validateRules.required(jobCreate.job_type, 'Job Type'),
                  ]"
                />
              </td>
            </tr>

            <tr>
              <td>
                <label for="tag">Quotation Number</label>
              </td>
              <td>
                <v-autocomplete
                  id="job-type"
                  dense
                  v-model="jobCreate.quotation"
                  :items="jobOption.quotations"
                  item-text="value"
                  item-color="cyan"
                  item-value="id"
                  filled
                  hide-details
                  placeholder="Quotation Number"
                  solo
                  flat
                  readonly
                  color="cyan"
                >
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td>
                <label for="po-number">PO Number</label>
              </td>
              <td>
                <v-text-field
                  id="po-number"
                  dense
                  v-model="jobCreate.po_number"
                  filled
                  hide-details
                  placeholder="PO Number"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr v-if="$route.query['enquiry']">
              <td>
                <label for="enquiry-number">Enquiry Number</label>
              </td>
              <td>
                <v-text-field
                  id="enquiry-number"
                  dense
                  v-model="jobCreate.enquiry_number"
                  filled
                  hide-details
                  placeholder="Enquiry Number"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="payment-mode">Payment Mode</label>
              </td>
              <td>
                <v-autocomplete
                  id="payment-mode"
                  dense
                  v-model="jobCreate.payment_mode"
                  :items="jobOption.payment_modes"
                  item-text="value"
                  item-color="cyan"
                  item-value="id"
                  filled
                  hide-details
                  placeholder="Payment Mode"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="payment-mode">Is Service Form</label>
              </td>
              <td>
                <span
                  class="font-weight-600 d-flex"
                  style="margin-top: -3px !important"
                >
                  <v-checkbox
                    color="cyan"
                    v-model="jobCreate.is_service_form"
                  ></v-checkbox>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <label for="payment-mode">Line Item Visible In PDF</label>
              </td>
              <td>
                <span
                  class="font-weight-600 d-flex"
                  style="margin-top: -3px !important"
                >
                  <v-checkbox
                    color="cyan"
                    v-model="jobCreate.is_item_pdf"
                  ></v-checkbox>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <label>Tags </label>
              </td>
              <td>
                <TagAutoComplete type="visit" v-model="jobCreate.tags" />
              </td>
            </tr>
          </table>
        </v-col>
        <v-col md="12" class="job-schedule pb-0">
          <v-tabs
            v-if="!isEditPage"
            v-model="jobCreate.schedule_tab"
            background-color="transparent"
            centered
            :disabled="jobLoading"
            color="cyan"
            icons-and-text
            class="mb-3"
            v-on:change="updateScheduleType($event)"
          >
            <v-tab
              :disabled="jobLoading"
              href="#one-off"
              style="min-width: 390px"
            >
              <div class="custom-tab-title d-flex">
                <div class="custom-tab-icon pr-2">
                  <v-icon left large color="gray">mdi-calendar</v-icon>
                </div>
                <div class="text-left">
                  <h3 class="text-uppercase font-weight-700">One-Off Job</h3>
                  <p class="m-0 text-capitalize">
                    A one time job with one or more visits
                  </p>
                </div>
              </div>
            </v-tab>
            <v-tab
              :disabled="jobLoading"
              href="#recurring"
              style="min-width: 390px"
            >
              <div class="custom-tab-title d-flex">
                <div class="custom-tab-icon pr-2">
                  <v-icon left large color="gray">mdi-calendar-multiple</v-icon>
                </div>
                <div class="text-left">
                  <h3 class="text-uppercase font-weight-700">Recurring Job</h3>
                  <p class="m-0 text-capitalize">
                    A contract job with repeating visits
                  </p>
                </div>
              </div>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-if="(isEditPage && isEditLoaded) || !isEditPage"
            v-model="jobCreate.schedule_tab"
            class="remvoe-overflow"
          >
            <v-tab-item value="one-off"> </v-tab-item>
            <v-tab-item value="recurring"> </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col md="12">
          <v-expansion-panels
            :disabled="jobLoading"
            flat
            tile
            multiple
            v-model="penel"
          >
            <v-expansion-panel
              v-if="jobCreate.schedule_tab == 'one-off'"
              class="mb-4 custom-border"
            >
              <v-expansion-panel-header color="grey lighten-4">
                <div class="my-3">
                  <h3
                    class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                  >
                    One-Off Job
                  </h3>
                  <p class="m-0 text-capitalize">
                    A one time job with one or more visits
                  </p>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <OneOffSchedule
                    :key="`job-one-off-schedule-${scheduleKey}`"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="jobCreate.schedule_tab == 'recurring'"
              class="mb-4 custom-border"
            >
              <v-expansion-panel-header color="grey lighten-4">
                <div class="my-3">
                  <h3
                    class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                  >
                    Recurring Job
                  </h3>
                  <p class="m-0 text-capitalize">
                    A contract job with repeating visits
                  </p>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <RecurringSchedule
                    :key="`job-recurring-schedule-${scheduleKey}`"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" ref="lineItem">
          <div class="customer-border">
            <div
              class="py-3 grey lighten-4"
              @click="show_line_items = !show_line_items"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_line_items ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">Line Items</h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-2" v-show="show_line_items">
                <LineItem
                  is-job
                  :related-type="related_type"
                  :related-detail="updatetedTicket"
                  :key="`job-line-item-${uniqueId}`"
                />
              </div>
            </v-expand-transition>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="customer-border">
            <div
              class="py-3 grey lighten-4"
              @click="show_terms_and_conditions = !show_terms_and_conditions"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_terms_and_conditions
                      ? "mdi-chevron-down"
                      : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">
                    Terms &amp; Conditions
                  </h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-3" v-if="show_terms_and_conditions">
                <v-autocomplete
                  id="payment-mode"
                  dense
                  v-model="jobCreate.term_condition_id"
                  :items="termsConditionList"
                  item-text="label"
                  item-color="cyan"
                  item-value="id"
                  class="pl-0 mb-3"
                  filled
                  hide-details
                  placeholder="Select Terms and conditions"
                  solo
                  flat
                  color="cyan"
                  v-on:change="update_term_condition()"
                />
                <TinyMCE v-model="jobCreate.term_conditions" />
              </div>
            </v-expand-transition>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="customer-border">
            <div
              class="py-3 grey lighten-4"
              @click="show_notes_and_attachment = !show_notes_and_attachment"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_notes_and_attachment
                      ? "mdi-chevron-down"
                      : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">
                    Notes &amp; Attachments
                  </h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-2" v-if="show_notes_and_attachment">
                <v-row>
                  <v-col cols="6">
                    <p class="font-weight-600 mb-2">
                      Client Note
                      <span class="text--red"> </span>
                    </p>
                    <TinyMCE v-model="jobCreate.client_remark" />
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex">
                      <span class="font-weight-600">
                        Admin Note (internal use only)
                      </span>
                      <v-spacer></v-spacer>
                      <span
                        class="font-weight-600 d-flex"
                        style="margin-top: -3px !important"
                      >
                        <v-checkbox
                          color="cyan"
                          v-model="jobCreate.is_remark_show"
                        ></v-checkbox>
                        <span class="mt-1">Show In Application</span>
                      </span>
                    </div>
                    <TinyMCE v-model="jobCreate.admin_remark" />
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <p class="font-weight-600 mb-1">
                      Attachments
                      <span class="text--red"></span>
                    </p>
                    <FileUpload
                      v-model="jobCreate.vPdfAttachments"
                      :maxLimit="5"
                    >
                    </FileUpload>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <p class="font-weight-600 mb-1">
                      Attachments (internal use only)
                    </p>
                    <FileUpload v-model="jobCreate.attachments" :maxLimit="5">
                    </FileUpload>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <!--  <CustomerSelect
      :key="`job-customer-select-${dailogKey}`"
      :dialog="customerDialog"
      v-on:close="customerDialog = false"
    /> -->

    <CustomerSelect :dialog="reCustomerDialog" @close="closeCustomerDialog" />
    <customer-create :key="`pm-customer-create-${dailogKey}`" />

    <PersonSelect
      :key="`job-person-select-${dailogKey}`"
      :dialog="contactDialog"
      v-on:close="contactDialog = false"
      :type="contactType"
    />
    <AddressSelect
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      :type="propertyType"
      :label="propertyLabel"
    />

    <!--  <AddressSelect
      :key="`job-address-select-${dailogKey}`"
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      type="property"
      label="Service Location"
      placeholder="Service Location"
    /> -->

    <template>
      <Dialog :common-dialog="confirmDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <div
              v-if="updateScheduleChanged"
              class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
              >
                <v-icon color="red darken-3" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Attention! Schedule has been Changed.
              </p>
            </div>

            <div
              v-if="updateTeamChanged"
              class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
              >
                <v-icon color="red darken-3" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Attention! Team has been Changed.
              </p>
            </div>

            <v-radio-group v-model="updateTargetType" row>
              <v-radio
                color="cyan"
                label="Update the current visit only?"
                value="self"
              ></v-radio>
              <v-radio
                color="cyan"
                label="Update all upcoming visits?"
                value="upcomming"
              ></v-radio>
            </v-radio-group>

            <div
              v-if="updateTargetType == 'upcomming'"
              class="p-4 mt-4 font-size-16 font-weight-400 warn-warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 orange--text text--darken-4"
              >
                <v-icon color="orange darken-4" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Note: The visits scheduled ahead will be changed with a new
                SCHEDULE and TEAM. Visit # will be updated accordingly.
              </p>
            </div>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            :disabled="jobLoading"
            v-on:click="
              scheduleChangeConfirmed = true;
              updateOrCreate();
            "
          >
            Continue
          </v-btn>
          <v-btn
            :disabled="jobLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="confirmDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import ValidationMixin from "@/core/plugins/validation-mixin";
import OneOffSchedule from "@/view/components/main-job/OneOffSchedule.vue";
import RecurringSchedule from "@/view/components/main-job/RecurringSchedule.vue";
import LineItem from "@/view/pages/partials/Line-Item-V2.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import AddressSelect from "@/view/components/main-job/AddressSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import {
  CLEAR_TICKET,
  GET_TICKET,
  GET_OPTION,
  SET_SCHEDULE_TYPE,
  CREATE_OR_UPDATE,
  SET_LOADING,
  SET_EDIT_MODE,
  UPDATE_SCHEDULE_STATE,
  GET_RECURRING_DATE,
  UPDATE_DEFAULT_DATE,
} from "@/core/services/store/job.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageJob from "@/core/plugins/job.plugin";
import moment from "moment-timezone";
import { UPLOAD } from "@/core/services/store/request.module";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import {
  SET_CREATE_DIALOG_STATUS,
  SET_DIALOG_STATUS,
  CLEAR_CUSTOMER,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
} from "@/core/services/store/customer.module";
import {
  RESET_STATE,
  RESET_CREATE_STATE,
} from "@/core/services/store/visit.module";
import { GET, QUERY } from "@/core/services/store/request.module";
import FileUpload from "@/view/components/app-component/FileInput.vue";

import {
  SET_EDIT_LINE_ITEM,
  SET_EDIT_CALCULATION,
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.module";

import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

export default {
  mixins: [ValidationMixin],
  name: "job-ctx-create",
  title: "Create Job",
  data() {
    return {
      confirmDialog: false,
      updateScheduleChanged: false,
      scheduleChangeConfirmed: false,
      updateTeamChanged: false,
      show_notes_and_attachment: false,
      updateTargetType: "self",
      invoiceId: 0,
      quotationId: 0,
      ticketId: 0,
      penel: [0],
      visitId: 0,
      related_type: 3,
      show_line_items: false,
      show_recurring_job: false,
      show_attachments: false,
      show_terms_and_conditions: false,
      duplicateTicketId: 0,
      duplicateVisitId: 0,
      dbPriventiveId: 0,
      dbEnquiryId: 0,
      dbschedule_id: 0,
      uniqueId: Number(new Date()),
      scheduleKey: Number(new Date()),
      dailogKey: Number(new Date()),
      formValid: true,
      customerDialog: false,
      contactDialog: false,
      propertyDialog: false,
      contactType: null,
      propertyType: null,
      updatetedTicket: {},
      vPdfAttachments: [],
      attachments: [],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      isEditPage: false,
      propertyLabel: null,
      isPreventivePage: false,
      updateTicketId: 0,
      updateVisitId: 0,
      isEditLoaded: false,
      jobCreate: {
        schedule_tab: "one-off",
        title: null,
        description: null,
        is_remark_show: false,
        schedule_later: false,
        is_service_form: true,
        is_item_pdf: true,
        priority: "3",
        term_condition_id: 0,
        reference: null,
        job_type: [],
        option: "internal",
        tags: null,
        po_number: null,
        quotation: 0,
        payment_mode: null,
        project: null,
        barcode: null,
        contract: null,
        invoice: null,
        term_conditions: null,
        client_remark: null,
        admin_remark: null,
        milestones: [],
        attachments: [],
        vPdfAttachments: [],
      },
    };
  },
  beforeCreate() {
    /*   KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on"); */
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  computed: {
    ...mapGetters([
      "defaultStartedAt",
      "defaultFinishedAt",
      "vDupDialog",
      "vDupVisit",
      "jobEditData",
      "jobOption",
      "lCalculation",
      "lSelectedLineItem",
      "scheduleOutput",
      "oneOffSchedule",
      "recurringSchedule",
      "assignedTeam",
      "reCustomerDialog",
      "jobLoading",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "recurringScheduleTypeList",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  components: {
    /*  Barcode, */
    Dialog,
    TinyMCE,
    LineItem,
    /*   CustomStatus, */
    CustomerSelect,
    "customer-create": CustomerCreate,
    TagAutoComplete,
    RecurringSchedule,
    OneOffSchedule,
    AddressSelect,
    PersonSelect,
    FileUpload,
    TextAreaField,
  },
  watch: {
    vCustomerId(param) {
      if (param) {
        this.getVisitOption();
      }
    },
  },
  methods: {
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    /*  openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
      }
    }, */
    createCustomerDialog() {
      this.dailogKey = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    update_term_condition() {
      let output = this.lodash.find(this.jobOption.term_condition_list, {
        id: this.jobCreate.term_condition_id,
      });
      if (output) {
        this.jobCreate.term_conditions = output.description;
      }
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },

    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    removeRow(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    updateFileName(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.addAttachmentRow();
      }
    },
    async updateOrCreate() {
      try {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Customer.")
          );
          return false;
        }

        if (!this.vPropertyId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Service Location.")
          );
          return false;
        }

        if (!this.vPropertyContactId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Contact Person.")
          );
          return false;
        }
        if (this.jobCreate.schedule_tab == "recurring") {
          if (!this.recurringSchedule.message) {
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Oops... Dates are not available, Please select valid schedule.."
              )
            );
            return false;
          }
        }
        const allHeaders = this.lSelectedLineItem.every(
          (obj) => obj.type === "header"
        );
        if (
          !this.lSelectedLineItem ||
          !this.lSelectedLineItem.length ||
          allHeaders
        ) {
          ErrorEventBus.$emit("update:error", "Select Line Item");
          return false;
        }

        const validateStatus = this.$refs.createTicketForm.validate();

        const formErrors = this.validateForm(this.$refs.createTicketForm);

        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        if (
          !this.scheduleChangeConfirmed &&
          this.isEditPage &&
          this.jobCreate.schedule_tab == "recurring" &&
          !this.isPreventivePage
        ) {
          this.updateScheduleChanged = false;
          this.updateTeamChanged = false;
          this.confirmDialog = false;

          const jobInstance = new ManageJob();

          if (
            !jobInstance.scheduleCompare(
              this.jobEditData,
              this.recurringSchedule
            )
          ) {
            this.confirmDialog = true;
            this.updateScheduleChanged = true;
            if (!jobInstance.teamCompare(this.jobEditData, this.assignedTeam)) {
              this.updateTeamChanged = true;
            }
            return false;
          }

          if (!jobInstance.teamCompare(this.jobEditData, this.assignedTeam)) {
            this.confirmDialog = true;
            this.updateTeamChanged = true;
            return false;
          }
        }

        /*  const new_attachments = await this.uploadFiles(); */
        const request = {
          ...this.jobCreate,
          default_started_at: this.defaultStartedAt
            ? this.defaultStartedAt
            : moment().format("YYYY-MM-DD HH:mm"),
          default_finished_at: this.defaultFinishedAt
            ? this.defaultFinishedAt
            : moment().format("YYYY-MM-DD HH:mm"),
          update_schedule_changed: +this.updateScheduleChanged,
          update_team_changed: +this.updateTeamChanged,
          update_target_type: this.updateTargetType,
          /*  attachments: this.jobCreate && this.jobCreate.attachments ? [...this.jobCreate.attachments, ...new_attachments]
              : [], */
          ticket: this.ticketId,
          visit: this.visitId,
          priventive:
            this.dbPriventiveId && this.dbPriventiveId > 0
              ? this.dbPriventiveId
              : 0,
          enquiry:
            this.dbEnquiryId && this.dbEnquiryId > 0 ? this.dbEnquiryId : 0,
          schedule_id:
            this.dbschedule_id && this.dbschedule_id > 0
              ? this.dbschedule_id
              : 0,
          customer: this.vCustomerId,
          property: this.vPropertyId,
          property_contact: this.vPropertyContactId,
          billing: this.vBillingId,
          billing_contact: this.vBillingContactId,
          calculation: this.lCalculation,
          "line-item": this.lSelectedLineItem,
          team: this.assignedTeam,
          clear_engineers: +!this.assignedTeam.length,
          /*   attachments:this.attachments,
          vPdfAttachments:this.vPdfAttachments, */
          oneoff:
            this.jobCreate.schedule_tab == "one-off" ? this.oneOffSchedule : {},
          recurring:
            this.jobCreate.schedule_tab == "recurring"
              ? this.recurringSchedule
              : {},
          schedule:
            this.jobCreate.schedule_tab == "recurring"
              ? this.scheduleOutput
              : [],
        };
        this.$store.commit(SET_LOADING, true);
        this.$store.dispatch(CREATE_OR_UPDATE, request).then(() => {
          this.$router.replace({
            name: "admin.visit",
            query: { status: "all" },
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit(SET_LOADING, false);
      }
    },
    updateScheduleType(param) {
      this.scheduleKey = Number(new Date());
      this.$store.commit(SET_SCHEDULE_TYPE, param);
    },
    getTicket() {
      this.$store
        .dispatch(GET_TICKET, {
          ticket: this.ticketId,
          visit: this.visitId,
        })
        .then(() => {
          this.isEditLoaded = true;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Visit", route: "visit" },
            { title: "Update" },
            { title: this.jobEditData?.visit?.barcode },
          ]);
          this.updatetedTicket = this.jobEditData?.ticket;
          let defaultTermConditionObject = this.lodash.find(
            this.jobOption.term_condition_list,
            {
              primary: 1,
            }
          );

          if (!this.lodash.isEmpty(defaultTermConditionObject)) {
            this.jobCreate.term_condition_id = defaultTermConditionObject.id;
            this.jobCreate.term_conditions =
              defaultTermConditionObject.description;
          }
          this.jobCreate = {
            schedule_tab:
              this.jobEditData?.ticket?.type == 2 ? "recurring" : "one-off",
            priority: this.jobEditData?.ticket?.priority,
            title: this.jobEditData?.ticket?.title,
            description: this.jobEditData?.ticket?.description,
            remark: this.jobEditData?.ticket?.additional_remarks,
            barcode: this.jobEditData?.ticket?.barcode,
            option: this.jobEditData?.ticket?.option,
            reference: this.jobEditData?.ticket?.reference,
            job_type: this.jobEditData?.ticket?.ticket_type,
            term_condition_id: this.jobEditData?.ticket?.term_condition_id,
            /*  collected_amount: this.jobEditData?.ticket?.amount_to_be_collected, */
            term_conditions: this.jobEditData?.ticket?.term_conditions,
            client_remark: this.jobEditData?.ticket?.client_remark,
            admin_remark: this.jobEditData?.ticket?.admin_remark,
            email_notify: this.jobEditData?.ticket?.notify_engineer,
            po_number: this.jobEditData?.ticket?.po_number,
            payment_mode: this.jobEditData?.ticket?.payment_mode,
            is_remark_show: this.jobEditData?.visit?.is_remark_show,
            tags: this.jobEditData?.ticket?.tags,
            quotation: this.jobEditData?.visit?.quotation,
            is_service_form: this.jobEditData?.visit?.is_service_form,
            is_item_pdf: this.jobEditData?.visit?.is_item_pdf,
            attachments: [],
          };
        })
        .catch(() => {
          /* this.goBack(); */
        })
        .finally(() => {
          //
        });
    },
    getCustomer(customerId, contactPersonId, propertyId) {
      this.$store
        .dispatch(QUERY, {
          url: `customer-with-relation/${customerId}`,
          data: {
            contact_person: contactPersonId,
            property: propertyId,
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.property_contact_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.billing_contact_person", {})
          );
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    getDuplicateTicket() {
      this.$store
        .dispatch(GET_TICKET, {
          ticket: this.duplicateTicketId,
          visit: this.duplicateVisitId,
        })
        .then(() => {
          this.jobCreate.schedule_tab =
            this.jobEditData?.ticket?.type == 2 ? "recurring" : "one-off";
          this.jobCreate.priority = this.jobEditData?.ticket?.priority;
          this.jobCreate.title = this.jobEditData?.ticket?.title;
          this.jobCreate.description = this.jobEditData?.ticket?.description;
          this.jobCreate.remark = this.jobEditData?.ticket?.additional_remarks;
          this.jobCreate.collected_amount =
            this.jobEditData?.ticket?.amount_to_be_collected;
          this.jobCreate.term_conditions =
            this.jobEditData?.ticket?.term_conditions;
          this.jobCreate.client_remark =
            this.jobEditData?.ticket?.client_remark;
          this.jobCreate.admin_remark = this.jobEditData?.ticket?.admin_remark;
          this.jobCreate.email_notify =
            this.jobEditData?.ticket?.notify_engineer;
          this.jobCreate.attachments = [];
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    getVisitOption() {
      this.$store
        .dispatch(GET_OPTION, {
          visit: this.lodash.toSafeInteger(this.updateVisitId),
          job: this.lodash.toSafeInteger(this.updateTicketId),
          priventive: this.lodash.toSafeInteger(this.dbPriventiveId),
          enquiry: this.lodash.toSafeInteger(this.dbEnquiryId),
          dbschedule_id: this.lodash.toSafeInteger(this.dbschedule_id),
          do_update: this.lodash.toSafeInteger(this.do_update),
          customer: this.lodash.toSafeInteger(this.vCustomerId),
        })
        .then((output) => {
          this.adminNoteList = output?.adminNoteList ?? [];
          this.clientNoteList = output?.clientNoteList ?? [];
          this.termsConditionList = output?.termsConditionList ?? [];

          if (
            this.visitId == 0 &&
            this.jobOption &&
            this.jobOption.service_form
          ) {
            if (this.jobOption.service_form.allow_service_form == 1) {
              this.jobCreate.is_service_form = true;
            } else {
              this.jobCreate.is_service_form = false;
            }
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          //
        });
    },
    getQuotation() {
      if (this.isEditPage) {
        return false;
      }

      this.$store
        .dispatch(GET, { url: `quotation-v1/${this.quotationId}` })
        .then(({ data }) => {
          this.jobCreate.title = data?.title;
          this.jobCreate.description = data?.description;
          this.jobCreate.reference = data?.reference;
          this.jobCreate.quotation = data?.id;
          this.jobCreate.job_type = ["Installation", "Service"];

          this.$store.commit(SET_CUSTOMER, data?.customer_relation);
          this.$store.commit(SET_PROPERTY, data?.property_relation);
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            data?.property_contact_person_relation
          );
          this.$store.commit(SET_BILLING, data?.billing_relation);
          this.$store.commit(
            SET_BILLING_CONTACT,
            data?.billing_contact_person_relation
          );

          this.$store.dispatch(SET_EDIT_CALCULATION, {
            additional_rows: data?.additional_rows ?? [],
            ctx_discount_label: data?.ctx_discount_label ?? null,
            ctx_discount_value: data?.ctx_discount_value ?? 0,
            ctx_discount_type: data?.ctx_discount_type ?? 1,
            discount_type: data?.discount_type ?? 1,
            discount_value: data?.discount_value ?? 0,
            tax_active: data?.tax_active ?? false,
            adjustment: data?.adjustment ?? 0,
            show_price: true,
            discount_level: data?.discount_level ?? "transaction",
          });

          this.$store.dispatch(SET_EDIT_LINE_ITEM, data?.line_item ?? []);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getInvoice() {
      if (this.isEditPage) {
        return false;
      }

      this.$store
        .dispatch(GET, { url: `invoice-v1/${this.invoiceId}` })
        .then(({ data }) => {
          this.jobCreate.title = data?.title;
          this.jobCreate.description = data?.description;
          this.jobCreate.reference = data?.reference;
          this.jobCreate.invoice = data?.id;
          this.jobCreate.quotation = data?.quotation ?? 0;

          this.$store.commit(SET_CUSTOMER, data?.customer_relation);
          this.$store.commit(SET_PROPERTY, data?.property_relation);
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            data?.property_contact_person_relation
          );
          this.$store.commit(SET_BILLING, data?.billing_relation);
          this.$store.commit(
            SET_BILLING_CONTACT,
            data?.billing_contact_person_relation
          );

          this.$store.dispatch(SET_EDIT_CALCULATION, {
            additional_rows: data?.additional_rows ?? [],
            ctx_discount_label: data?.ctx_discount_label ?? null,
            ctx_discount_value: data?.ctx_discount_value ?? 0,
            ctx_discount_type: data?.ctx_discount_type ?? 1,
            discount_type: data?.discount_type ?? 1,
            discount_value: data?.discount_value ?? 0,
            tax_active: data?.tax_active ?? false,
            adjustment: data?.adjustment ?? 0,
            show_price: true,
            discount_level: data?.discount_level ?? "transaction",
          });

          this.$store.dispatch(SET_EDIT_LINE_ITEM, data?.line_item ?? []);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getEnquiry() {
      if (this.isEditPage) {
        return false;
      }

      this.$store
        .dispatch(GET, { url: `enquiry/${this.dbEnquiryId}` })
        .then(({ data }) => {
          this.jobCreate.title = data?.title;
          this.jobCreate.enquiry_number = data?.barcode;
          this.jobCreate.reference = data?.reference;
          this.jobCreate.enquiry = data?.id;
          this.jobCreate.quotation = data?.quotation;

          this.$store.commit(SET_CUSTOMER, data?.customer_relation);
          this.$store.commit(SET_PROPERTY, data?.property_relation);
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            data?.property_person_relation
          );
          this.$store.commit(SET_BILLING, data?.billing_relation);
          this.$store.commit(
            SET_BILLING_CONTACT,
            data?.billing_person_relation
          );
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
  created() {
    this.quotationId = Number(this.$route?.query?.quotation ?? 0);

    this.ticketId = Number(this.$route?.query?.ticket ?? 0);
    this.visitId = Number(this.$route?.query?.visit ?? 0);
    this.invoiceId = Number(this.$route?.query?.invoice ?? 0);
    this.duplicateTicketId = Number(
      this.$route?.query["duplicate-ticket"] ?? 0
    );
    this.duplicateVisitId = Number(this.$route?.query["duplicate-visit"] ?? 0);
    this.dbPriventiveId = this.lodash.toSafeInteger(
      this.$route.query?.priventive ?? 0
    );
    this.dbEnquiryId = this.lodash.toSafeInteger(
      this.$route.query?.enquiry ?? 0
    );
    this.dbschedule_id = this.lodash.toSafeInteger(
      this.$route.query?.schedule_id ?? 0
    );
  },
  destroyed() {
    this.$store.dispatch(CLEAR_TICKET);
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  mounted() {
    this.getVisitOption();

    if (this.duplicateTicketId && this.duplicateVisitId) {
      this.getDuplicateTicket();
    }

    const customerId = Number(this.$route?.query?.customer ?? 0);
    const contactPersonId = Number(this.$route?.query?.contact_person ?? 0);
    const propertyId = Number(this.$route?.query?.property ?? 0);

    if (customerId) {
      this.getCustomer(customerId, contactPersonId, propertyId);
    }

    if (this.ticketId && this.visitId) {
      this.getTicket();
      this.isEditPage = true;
      this.$store.commit(SET_EDIT_MODE, true);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit", route: "visit" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit", route: "visit" },
        { title: "Create" },
      ]);
    }

    this.$refs.createTicketForm.validate();

    if (typeof this.dbEnquiryId == "number" && this.dbEnquiryId > 0) {
      this.getEnquiry();
    } else if (typeof this.quotationId == "number" && this.quotationId > 0) {
      this.getQuotation();
    } else if (typeof this.invoiceId == "number" && this.invoiceId > 0) {
      this.getInvoice();
    } else {
      this.goBack();
    }
  },
};
</script>
<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
