<template>
  <div class="schedule-tab-item ml-3">
    <div class="width-35 custom-schedule-calendar mx-auto">
      <v-btn fab text small v-on:click="prev" color="grey darken-2">
        <v-icon small> mdi-chevron-left </v-icon>
      </v-btn>
      <v-toolbar-title
        class="margin-auto font-weight-500"
        v-if="$refs.scheduleCalendar"
      >
        {{ $refs.scheduleCalendar.title }}
      </v-toolbar-title>
      <v-btn fab text small v-on:click="next" color="grey darken-2">
        <v-icon small> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
    <div class="width-100">
      <v-calendar
        ref="scheduleCalendar"
        type="month"
        color="cyan"
        v-model="scheduleCalendar"
        :events="events"
        :event-color="getEventColor"
        v-on:click:event="showEvent"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon v-on:click="selectedOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.details"></span>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      scheduleCalendar: null,
      events: new Array(),
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  props: {
    showCalendar: Boolean,
  },
  methods: {
    prev() {
      this.$refs.scheduleCalendar.prev();
    },
    next() {
      this.$refs.scheduleCalendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventColor(event) {
      return event.color;
    },
    updateEvents() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, { url: "calendar/visits" })
        .then(({ data }) => {
          _this.events = data;
        })
        .catch((error) => {
          _this.logError(error);
        });

      /*this.events.push({
        name: 'Installation Mobile Devices',
        start: new Date(),
        end: new Date('2021-01-14'),
        details: '<h1>Hello World</h1>',
        color: 'cyan',
        timed: true,
      })*/
    },
  },
  mounted() {
    this.updateEvents();
    this.$refs.scheduleCalendar.checkChange();
  },
};
</script>
